<app-modal-header-common title="Portfolio Info"> </app-modal-header-common>
<div class="plan-form">
  <div>
    <h6>{{ this.data.portfolioInfo.name }}</h6>
    <div
      class="custom-table table-responsive"
      style="max-height: calc(100vh - 220px)"
    >
      <table class="table table-bordered">
        <thead style="position: sticky; z-index: 1">
          <tr>
            <th
              *ngFor="let field of tableColumns"
              scope="col"
              class="text-center"
            >
              {{ field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let element of porfolioTableInfo; let i = index"
          >
            <tr>
              <td>
                {{ element?.name }}
              </td>
              <td>{{ element?.risk_id_count }}</td>
              <td>{{ element?.projects?.length }}</td>
            </tr>
          </ng-container>
        </tbody>
        <tr *ngIf="!porfolioTableInfo?.length">
          <td colspan="14" class="noRecords text-center mt-2">
            <span class="mt-2">{{ staticText.common.no_records_found }} </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div>
    <h6>OKRs Impacted -</h6>
    <div class="table" style="margin-bottom: 0px !important">
      <div class="table-row">
        <div
          class="table-cell cursor-pointer fw-bold"
          style="
            max-width: 150px;
            min-width: 150px;
            width: 150px;
            font-size: 14px;
            position: sticky;
            left: 0;
            z-index: 1;
            height: 0;
          "
        >
          <div
            class="domain"
            style="background-color: #0bb797; border-radius: 10px; height: 100%"
          >
            Objectives
          </div>
        </div>
        <div class="table-cell table-nest">
          <div class="table-row" style="position: relative">
            <div
              class="table-cell cursor-pointer"
              style="
                max-width: 150px;
                min-width: 150px;
                font-size: 14px;
                position: sticky;
                left: 150px;
                z-index: 1;
                height: 0;
              "
            >
              <div
                class="subdomain"
                style="
                  background-color: #0bb797;
                  border-radius: 10px;
                  padding: 8px;
                  height: 100%;
                "
              >
                Key Results
              </div>
            </div>
            <div
              class="table-cell"
              style="
                max-width: 150px;
                min-width: 150px;
                position: relative;
                padding: 5px;
                cursor: pointer;
              "
            >
              <span
                style="
                  position: absolute;
                  font-size: 14px;
                  text-align: center;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 90%;
                  height: 100%;
                  padding: 7px 0;
                "
              >
                <div
                  style="
                    background-color: #0bb797;
                    color: white;
                    border-radius: 10px;
                    padding: 8px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  Goals
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow: scroll; height: calc(70vh - 290px)">
      <div class="container-fluid p-0">
        <div class="table">
          <div
            class="table-row"
            *ngFor="let objective of goalInfo?.objectives; let subindex = index"
          >
            <div
              class="table-cell cursor-pointer fw-bold"
              style="
                max-width: 150px;
                min-width: 150px;
                font-size: 13px;
                position: sticky;
                left: 0;
                z-index: 1;
                height: 0;
              "
            >
              <div class="domain" style="border-radius: 10px; height: 100%">
                {{ objective?.title }}
              </div>
            </div>
            <div class="table-cell table-nest" *ngIf="objective.key_results">
              <div
                class="table-row"
                style="position: relative"
                *ngFor="
                  let kr of objective?.key_results;
                  let subsubindex = index
                "
              >
                <div
                  class="table-cell cursor-pointer"
                  id="subdomain_{{ subsubindex }}"
                  style="
                    max-width: 150px;
                    min-width: 150px;
                    font-size: 13px;
                    position: sticky;
                    left: 150px;
                    z-index: 1;
                    height: 0;
                  "
                >
                  <div
                    class="subdomain"
                    style="border-radius: 10px; padding: 8px; height: 100%"
                    [matTooltip]="kr?.title | titlecase"
                  >
                    {{ kr?.title }}
                  </div>
                </div>
                <div
                  class="table-cell"
                  style="
                    max-width: 150px;
                    min-width: 150px;
                    position: relative;
                    padding: 5px;
                    cursor: pointer;
                  "
                  *ngFor="let goal of kr?.goals; let goalsubindex = index"
                >
                  <span
                    id="goalsubdomain_{{ subsubindex }}_{{ goalsubindex }}"
                    style="
                      position: absolute;
                      font-size: 13px;
                      text-align: center;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 90%;
                      height: 100%;
                      padding: 5px 0;
                    "
                  >
                    <div
                      [ngStyle]="{
                        backgroundColor: '#c2bec7'
                      }"
                      [matTooltip]="goal?.goal_name | titlecase"
                      style="
                        border-radius: 10px;
                        padding: 0 10px;
                        padding-bottom: 30px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.75rem;
                        text-align: left;
                      "
                    >
                      {{ getTrucatedName(kr?.title, goal?.goal_name) }}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
