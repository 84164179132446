import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";

@Component({
  selector: "app-portfolio-dialog",
  templateUrl: "./portfolio-dialog.component.html",
  styleUrls: ["./portfolio-dialog.component.scss"],
})
export class PortfolioDialogComponent implements OnInit {
  tableColumns: any = ["Subportfolio", "Project Count", "Risk Count"];
  porfolioTableInfo: any = null;
  goalInfo: any = null;
  staticText: any = (textConfiguration as any).default;

  constructor(
    public dialogRef: MatDialogRef<PortfolioDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scorecardService: ScorecardService
  ) {}

  ngOnInit(): void {
    this.getPortfolioAndGoals();
  }

  getPortfolioInfo() {
    this.scorecardService
      .getPortfolioInfo(this.data.portfolioInfo.id)
      .subscribe((res: any) => {
        this.porfolioTableInfo = res.subportfolio;
      });
  }

  getGoalsInfo() {
    this.scorecardService
      .getGoalInfo(this.data.portfolioInfo.id)
      .subscribe((res: any) => {
        this.goalInfo = res;
      });
  }

  getPortfolioAndGoals() {
    this.getPortfolioInfo();
    this.getGoalsInfo();
  }

  getTrucatedName(subdomain: string, name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > subdomain.length) {
        return (
          name.substring(
            0,
            subdomain.length - 1 > 10 ? subdomain.length - 15 : 10
          ) + "..."
        );
      } else {
        return name;
      }
    }
  }
}
